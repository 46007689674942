<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      class="dialog"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline pb-0">
          <v-col cols="10" class="modal-title">Comprovante de Transferência</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
        </v-card-title>
        <v-divider class="mx-6 ml-6"></v-divider>
        <v-card-text id="contentReceipt" class="pb-0 pl-md-12 pr-md-12">
          <v-container class="pb-0">
            <v-row justify="center">
              <v-col cols="12" class="mt-md-2" align="center">
                <img src="@/assets/success.svg" />
              </v-col>
              <v-col cols="12" align="center">
                <h5 class="sendTransfer">Transferência Realizada</h5>
              </v-col>
              <v-col cols="12" align="center">
                <h5 class="valuePayment">Valor da Transferência</h5>
              </v-col>
              <v-col class="contentValue" cols="8" md="6" align="center">
                <h2>{{service.formatCurrency(getPaymentPix.totalAmountTransferred)}}</h2>
              </v-col>

              <v-col cols="12" align="center" class="mt-5" v-if="getPaymentPix.description"> 
                    <span class="pb-0 pt-0">
                        <b>Descrição</b>
                    </span>  
              </v-col>
              <v-col cols="12" align="center" class="msg-desc-transfer pt-6 pb-6 pl-3 pr-3" v-if="getPaymentPix.description"> 
                <span class="pb-0 pt-0">
                    {{getPaymentPix.description}}
                </span>    
              </v-col>
              <v-col cols="12" align="center" v-if="getPaymentPix.origin">
                <v-row justify="center">
                  <v-col cols="12" align="center">
                    <h5 class="titleSections">Origem</h5>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Nome</h5>
                    <span class="valueData">{{getPaymentPix.origin.name}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Agência</h5>
                    <span class="valueData">{{getPaymentPix.origin.bankInformation.branch}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Conta</h5>
                    <span class="valueData">{{getPaymentPix.origin.bankInformation.account}}</span>
                  </v-col>
                  <v-col cols="12" align="center" class="data-receipt">
                    <h5 class="titleSections">Destino</h5>
                  </v-col>
                  <v-col cols="4" v-if="getPaymentPix.receiver.name" class="data-receipt">
                    <h5 class="labelData">Nome</h5>
                    <span class="valueData">{{getPaymentPix.receiver.name}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">CPF/CNPJ</h5>
                    <span class="valueData" v-if="getPaymentPix.receiver.document.length === 11">{{service.personDocumentFormat(getPaymentPix.receiver.document)}} </span>
                    <span class="valueData" v-else>{{service.documentFormat(getPaymentPix.receiver.document)}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Banco</h5>
                    <span class="valueData">{{getPaymentPix.receiver.bankInformation.paymentServiceProvider.name}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Agência</h5>
                    <span class="valueData">{{getPaymentPix.receiver.bankInformation.branch}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Conta</h5>
                    <span class="valueData">{{getPaymentPix.receiver.bankInformation.account}}</span>
                  </v-col>
                  <v-col cols="4" class="data-receipt">
                    <h5 class="labelData">Tipo de Operação</h5>
                    <span class="valueData">{{getPaymentPix.transactionType}}</span>
                  </v-col>
                  <v-col cols="12" class="pb-0 mb-0">
                    <h5 class="titleSections">Data e hora da efetivação da transferênca</h5>
                  </v-col>
                  <v-col cols="12">
                    <span
                      class="valueData"
                    >{{getPaymentPix.transactionDate}}</span>
                  </v-col>
                  <v-col cols="12" class="pb-0 mb-0">
                    <h5 class="titleSections">ID da transação</h5>
                  </v-col>
                  <v-col cols="12">
                    <span class="valueData">{{getPaymentPix.transactionId}}</span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0 pl-md-12 pr-md-12">
          <v-container class="pt-0 pb-12 pb-md-3">
            <v-row v-if="!sendToEmail" justify="center">
              <v-col cols="12" class="msgSuccess" v-if="msgSuccess">
                  <b>Email enviado com sucesso!</b>
              </v-col>
              <v-col cols="12" md="6" aling="center">
                <v-btn
                  color="#00c1c1"
                  class="button-modal"
                  outlined
                  rounded
                  @click="seeSendEmail(true)"
                >Enviar por e-mail</v-btn>
              </v-col>
              <v-col cols="12" md="6" aling="center"  v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn @click="print" color="#00c1c1" class="button-modal" dark rounded>Imprimir</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="sendToEmail" justify="center">
              <v-col cols="3" md="2" class="mt-2" @click="seeSendEmail(false)" v-if="$vuetify.breakpoint.mdAndUp">
                <span class="cancelEmail">Cancelar</span>
              </v-col>
              <v-form cols="8" class="pt-3" lazy-validation aling="center" ref="form">
                <v-text-field
                  type="email"
                  class="sendEmail"
                  placeholder="Insira o e-mail"
                  v-model="email"
                  validate-on-blur
                  outlined
                  :rules="emailValidation"
                ></v-text-field>
              </v-form>
              <v-col class="send" cols="2" @click="sendEmailReceipt" aling="center">
                <img src="@/assets/send.png" alt />
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import service from "../../../../service/index";

export default {
  props: ["dialog"],
  data() {
    return {
      service,
      sendToEmail: false,
      email: "",
      msgSuccess: false,
      emailValidation: [
        (valueInput) => !!valueInput || "Insira o e-mail",
        (valueInput) =>
          !valueInput || /.+@.+\..+/.test(valueInput) || "E-mail inválido",
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'DigitalAccount/getAccount',
      getUserInfo: 'User/getInfo',
      getPaymentPix: 'Pix/getPaymentPix',
      getError: 'DigitalAccount/getError',
    }),
  },
  methods: {
    ...mapActions({
      sendEmail: 'DigitalAccount/sendEmail',

    }), 
    print() {
      this.msgSuccess = false
      const prtHtml = document.getElementById("contentReceipt").innerHTML;

      const styles = [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ].reduce((acc, item) => acc + item.outerHTML, "");

      const printWindow = window.open(
        "",
        "",
        "left=0,top=0,height=900,toolbar=100,scrollbars=0,status=0"
      );

      printWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${styles}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);
      this.$emit("closeModal", false);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();      
      printWindow.close();

    if (window.stop) {
        location.reload(); //triggering unload (e.g. reloading the page) makes the print dialog appear
        window.stop(); //immediately stop reloading
    }      printWindow.close();
    },
    closeModal() {
      this.sendToEmail = false;
      this.msgSuccess = false;
      this.$emit("closeModal");
    },
    seeSendEmail(value){
      this.sendToEmail = value
      this.msgSuccess = false
      this.email = ""
    },
    async sendEmailReceipt() {
      if (this.$refs.form.validate()) {
        await this.sendEmail({
          id: this.getPaymentPix.transactionId,
          email: this.email,
        });
        this.seeSendEmail(false);
        if(!this.getError) {
          this.msgSuccess = true
        }
     }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/modalReceipt.scss';
@import '~@/assets/styles/digitalAccount/pix.scss';
</style>
