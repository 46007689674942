<template>
  <v-row class="rowDialog">
    <v-dialog
      persistent
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="430"
      class="dialog"
    >
      <v-card class="contentCard">
        <v-card-title class="headline pb-0">
          <v-col cols="10" class="modal-title-pix">Transferir</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
        </v-card-title>
        <v-divider class="mx-6 ml-6"></v-divider>
        <v-col v-if="getLoading" cols="12" align="center">
          <v-progress-circular :size="100" color="#D70472" indeterminate></v-progress-circular>
          <h3 class="textInfoDisable mt-6">Confirmando transferência...</h3>
        </v-col>
        <v-card-text v-show="!getLoading" class="pt-7">
          <v-row v-if="inputPass && !emailSended && !erro" justify="center">
            <v-col md="6" cols="7" class="pt-5 pb-0" align="center">
              <p class="title-password-transfer">
                Para completar a operação, insira sua senha digital:
              </p>
            </v-col>
            <v-col md="8" cols="10" class="mt-0 mb-3 password">
              <center><small>Insira a senha de 6 dígitos</small></center>
              <v-text-field
                class="password-field"
                maxlength="6"
                placeholder="------"
                rounded
                type="password"
                v-mask="'######'"
                single-line
                dense
                outlined
                v-model="password"
              />
            </v-col>
            <v-col cols="12" align="center" class="forgetPassword cursor" @click="forgetMyPassword">
              <h5 class="forgetPassword cursor">Esqueci minha senha.</h5>
            </v-col>
            <v-col cols="12" md="8" class="pb-5 pt-12" align="center">
              <v-btn
                @click="validatePass()"
                rounded
                color="#D70472"
                :dark="password.length === 6"
                :light="password.length !== 6"
                class="button-modal pa-6 pa-md-1"
                :disabled="password.length !== 6"
                >Confirmar</v-btn
              >
              <v-btn
                @click="closeModal"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1 mt-5 mt-md-3"
                >Cancelar</v-btn
              >
            </v-col>
          </v-row>

          <v-row justify="center" v-if="erro">
            <v-col cols="12">
              <v-row justify="center">
                <img class="mt-md-5 mt-12" src="@/assets/erro.png" />
              </v-row>
            </v-col>
            <v-col cols="9" md="8" class="pb-10">
              <v-row justify="center">
                <span class="text-erro">Erro na transferência</span>
                <span class="text-confirm">{{ erro }}</span>
                <v-btn
                  @click="closeModal"
                  rounded
                  color="#8C8C8C"
                  outlined
                  class="button-modal pa-6 pa-md-1 mt-5"
                  >Fechar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>

          <v-row justify="center" v-if="emailSended">
            <v-col cols="12">
              <v-row justify="center">
                <img class="imgSuccess mt-md-5 mt-12" src="@/assets/email.png" />
              </v-row>
            </v-col>
            <v-col cols="9" md="6" class="pb-3">
              <v-row justify="center">
                <span class="successTitle">
                  Enviamos as insruções para o seu e-mail cadastrado!
                </span>
              </v-row>
            </v-col>
            <v-col cols="11" md="12" class="pt-0">
              <v-row justify="center">
                <v-btn rounded color="#8C8C8C" outlined @click="closeModal" class="btnClose">
                  Fechar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="!inputPass && !erro" justify="center">
            <v-col cols="12" class="mt-0" align="center">
              <img src="@/assets/successPink.png" />
            </v-col>
            <v-col cols="12" align="center" class="pb-1 pt-2">
              <h2 class="title-password-transfer">Transferência realizada!</h2>
            </v-col>
            <v-col cols="12" md="8" align="center" v-if="getTransferReturn.name">
              <h2 class="infoSuccess-title">Parabéns</h2>
              <h5 class="infoSuccess mt-0">
                Sua transferência no valor de
                <b>{{ service.formatCurrency(getTransferReturn.totalAmount) }}</b>
                para
                <b>{{ getTransferReturn.name }}</b>
                foi realizada em
                <b>{{ todayDate }}</b>
                com sucesso.
              </h5>
            </v-col>
            <v-col cols="12" md="8" align="center" v-else>
              <h2 class="infoSuccess-title">Parabéns</h2>
              <h5 class="infoSuccess mt-0">
                Sua transferência no valor de
                <b>{{ service.formatCurrency(getTransferReturn.totalAmount) }}</b>
                foi realizada em
                <b>{{ todayDate }}</b>
                com sucesso.
              </h5>
            </v-col>
            <v-col md="9" cols="12" class="pb-5" align="center">
              <!-- <v-btn
                @click="openModalTransfer"
                rounded
                color="#D70472"
                dark
                class="button-modal pa-6 pa-md-1"
                >Ver Comprovante</v-btn
              > -->
              <v-btn
                @click="closeModal"
                rounded
                color="#8C8C8C"
                outlined
                class="button-modal pa-6 pa-md-1 mt-5"
                >Fechar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import service from "../../../../service/index";

export default {
  directives: {
    mask
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    totalAmount: {
      type: [String, Number, Boolean]
    },
    transactionId: {
      type: String
    },
    message: {
      type: String
    }
  },
  data() {
    return {
      service,
      password: "",
      inputPass: true,
      emailSended: false
    };
  },
  computed: {
    ...mapGetters({
      getTransferToken: "Pix/getTransferToken",
      erro: "Pix/getError",
      getTransferReturn: "Pix/getTransferReturn",
      emailSuccess: "DigitalAccount/getEmailPassword",
      getLoading: "Loading/getLoading"
    }),

    todayDate() {
      return this.$moment().format("DD/MM/YYYY HH:mm:ss");
    }
  },
  methods: {
    ...mapActions({
      generatePixToken: "Pix/generatePixToken",
      instantPaymentTransfer: "Pix/instantPaymentTransfer",
      forgetPassword: "DigitalAccount/forgetPassword",
      setBalance: "DigitalAccount/setBalance"
    }),
    closeModal() {
      if (!this.inputPass && !this.erro) {
        this.$router.push("/customer/digitalAccount/bankStatement");
        return;
      }
      this.inputPass = false;
      this.password = "";
      this.emailSended = false;
      this.dialog = false;
      this.$emit("closeModal");
    },
    async forgetMyPassword() {
      await this.forgetPassword();
      if (this.emailSuccess) {
        this.emailSended = true;
      }
    },
    async validatePass() {
      if (this.password.length === 6) {
        await this.generatePixToken({
          password: this.password
        });
        if (!this.erro) {
          await this.instantPaymentTransfer({
            token: this.getTransferToken.token,
            totalAmount: this.service.cleanCurrency(this.totalAmount),
            description: this.message,
            transactionId: this.transactionId
          });
          this.setBalance();
          this.inputPass = false;
        }
      }
    },
    async openModalTransfer() {
      await this.$emit("openDialogTransferReturn");
      this.dialog = false;
      //this.closeModal();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/dataTransference.scss";
@import "~@/assets/styles/digitalAccount/pix.scss";
</style>
