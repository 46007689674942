<template>
  <v-container cols="12" md="9" :class="{ 'pt-0': $vuetify.breakpoint.mdAndDown }">
    <ModalTransfer
      :dialog="dialogTransfer"
      @closeModal="closeModalTransfer"
      @openDialogReceipt="openDialogReceipt"
    />
    <ModalReceipt :dialog="dialogReceipt" @closeModal="closeModalReceipt" />
    <v-row justify="center">
      <v-col md="9" cols="12">
        <v-col cols="12" md="6" class="pb-0">
          <v-row align="center" v-if="$vuetify.breakpoint.mdAndUp">
            <v-col class="col-2 pr-0 pb-0">
              <img class="icon-transfer" src="@/assets/icone_transferis.png" />
            </v-col>
            <v-col class="col-10 title-transfer pl-0 pb-0">
              Transferir
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="isStartTransfer" cols="12" md="6" class="pt-0">
          <v-row v-if="$vuetify.breakpoint.mdAndDown">
            <v-col class="title-transfer">
              Transferir
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col class="description-transfer mb-4 pt-0">
              Escolha uma das opções abaixo:
            </v-col>
          </v-row>
          <v-col
            class="select-cards-open card-myArea card-transfer-pix pl-4 pr-4 mb-4"
            justify="center"
            @click="goToInsertDataAccount"
          >
            <v-row align="center">
              <v-col class="col-2">
                <img class="icon-transfer" src="@/assets/icone_dados.png" />
              </v-col>
              <v-col class="col-8">
                Dados da Conta
              </v-col>
              <v-col class="col-2">
                <img src="@/assets/arrow-next.png" />
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="select-cards-open card-myArea card-transfer-pix pl-4 pr-4"
            justify="center"
            @click="goToReview"
          >
            <v-row align="center">
              <v-col class="col-2">
                <img class="icon-transfer" src="@/assets/icone_chave.png" />
              </v-col>
              <v-col class="col-8">
                Chave Pix
              </v-col>
              <v-col class="col-2">
                <img src="@/assets/arrow-next.png" />
              </v-col>
            </v-row>
          </v-col>
        </v-col>

        <!-- Selecionar chave -->
        <v-col v-if="isSelectKey" cols="12" md="8">
          <v-row>
            <v-col cols="12" align="left" class="title-transfer pt-0">
              <span>Informe a chave</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="description-transfer mb-4 pt-0">
              <span>Insira o dado no tipo de chave correspondente</span>
            </v-col>
          </v-row>
          <v-row v-if="$vuetify.breakpoint.mdAndUp">
            <v-col class="text pb-0 pb-md-5 pt-0">
              <strong>Chave Pix</strong>
            </v-col>
          </v-row>
          <v-form ref="formKey" lazy-validation>
            <v-col cols="12" md="9" class="pl-0 pr-0 pb-0 pt-0 options-keys">
              <v-col
                v-if="
                  (selectChangeKey && $vuetify.breakpoint.mdAndUp) || $vuetify.breakpoint.smAndDown
                "
                md="6"
                class="pl-0 pr-0"
              >
                <v-select
                  :items="optionsKeys"
                  label="Tipo de chave"
                  placeholder="Selecione"
                  color="#D70472"
                  v-model="selectTypeKey"
                  item-text="name"
                  :rules="selectKeyRules"
                  validate-on-blur
                >
                  <template slot="item" slot-scope="data">
                    <v-row class="drop-list-select-keys" @click="changeKey()">
                      <v-col cols="12" class="pb-0">
                        <strong>{{ data.item.type }} </strong></v-col
                      >
                      <v-col cols="12" class="pt-0"> {{ data.item.name }} </v-col>
                    </v-row>
                  </template>
                </v-select>
              </v-col>
              <v-row
                v-if="
                  (!selectChangeKey && $vuetify.breakpoint.mdAndUp) ||
                    (selectTypeKey && $vuetify.breakpoint.smAndDown)
                "
              >
                <v-col cols="12" md="8" class="pt-0 pr-0">
                  <v-text-field
                    v-if="isKeyWithMask"
                    :label="selectTypeKey"
                    :placeholder="`Insira o ${selectTypeKey} `"
                    :rules="getRuleSelectTypeKey()"
                    v-model="valueSelectKey"
                    validate-on-blur
                    v-mask="getFormatMask()"
                  >
                  </v-text-field>
                  <v-text-field
                    v-show="!isKeyWithMask"
                    :label="selectTypeKey"
                    :placeholder="`Insira o ${selectTypeKey} `"
                    :rules="getRuleSelectTypeKey()"
                    validate-on-blur
                    v-model="valueSelectKey"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.mdAndUp"
                  cols="4"
                  class="pl-0 pt-0"
                  align="right"
                  align-self="center"
                >
                  <a class="link-change" @click="changeKey()">
                    Alterar chave
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
          <v-row class="row-buttons-create pt-md-3 pt-12">
            <v-col cols="12" md="5">
              <v-btn
                @click="goToStartTransfer"
                class="button"
                color="#C4C4C4"
                :x-large="$vuetify.breakpoint.smAndDown"
                block
                rounded
                dark
              >
                Voltar
              </v-btn>
            </v-col>
            <v-col cols="12" md="5">
              <v-btn
                class="button"
                @click="goToInputData"
                color="#D70472"
                :x-large="$vuetify.breakpoint.smAndDown"
                :disabled="btnContinue"
                :dark="!btnContinue"
                :light="btnContinue"
                block
                rounded
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- Dados Bancários -->
        <v-col v-if="isInsertDataAccount" cols="12" md="8">
          <v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="9" class="pl-0 pr-0 pb-0">
              <v-row class="title title-mobile-transfer">
                Saldo total disponível na conta digital:
              </v-row>
              <v-row>
                <v-col cols="8" class="pt-2 pl-0 pb-0">
                  <v-text-field
                    class="value-account-balance"
                    :value="service.formatCurrency(getDigitalBalance)"
                    single-line
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="7" class="pl-0 pr-0 pb-0 pt-0">
              <v-row class="title-transfer title-mobile">
                Dados da transferência
              </v-row>
            </v-col>
            <v-form ref="formDataValue" lazy-validation>
              <v-row>
                <v-col cols="12" md="7" class="pl-0 pr-0 ml-0 pb-0">
                  <v-text-field
                    ref="value"
                    class="input-collect"
                    @change="editValue"
                    v-money="money"
                    required
                    maxlength="20"
                    validate-on-blur
                    :rules="valueRules"
                    v-model.lazy="value"
                    :append-icon="showIconEditValue ? 'mdi-pencil' : undefined"
                    @click:append="enableEditValue"
                    :readonly="valueIsReadOnly"
                    color="#D70472"
                    >{{ value }}</v-text-field
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" class="pl-0 pr-0 pt-8">
                Saldo em conta:<strong> {{ service.formatCurrency(getDigitalBalance) }} </strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-0 pr-0 pb-0">
            <h4>Descrição</h4>
          </v-col>
          <v-col cols="12" md="12" class="input-msg pl-0 pt-0 pb-md-0 pb-12 pr-0">
            <v-textarea
              class="box-msg pb-0 pt-0"
              background-color="#F2F2F2"
              placeholder="Escreva uma mensagem..."
              v-model="msg"
              maxlength="140"
              counter="140"
              color="#D70472"
            ></v-textarea>
          </v-col>
          <v-col cols="12" md="9" class="pa-0 pt-md-3 pt-12 mt-md-3 mt-12">
            <strong>Dados Bancários</strong>
          </v-col>
          <v-form ref="formDataBank" lazy-validation>
            <v-row>
              <v-col cols="12" md="9" class="pt-0">
                <v-text-field
                  label="CPF/CNPJ"
                  placeholder="00.000.000/0000-00"
                  :rules="personalDocumentRules"
                  validate-on-blur
                  v-model="document"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-4">
                <v-autocomplete
                  :items="getBanks.data.psps"
                  label="Banco"
                  placeholder="Selecione"
                  color="#D70472"
                  validate-on-blur
                  v-model="selectBank"
                  :rules="bankRules"
                  item-text="name"
                  dense
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="6" class="pt-4">
                <v-select
                  :items="optionsTypeAccount"
                  label="Tipo de conta"
                  placeholder="Selecione"
                  color="#D70472"
                  :rules="accountTypeRules"
                  validate-on-blur
                  v-model="selectTypeAccount"
                  item-text="type"
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="pt-0">
                <v-text-field
                  label="Agência"
                  placeholder="0000"
                  maxlength="4"
                  :rules="branchRules"
                  validate-on-blur
                  v-model="branch"
                  v-mask="'####'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="4" class="pt-0">
                <v-text-field
                  label="Conta"
                  maxlength="18"
                  placeholder="0000"
                  v-model="account"
                  :rules="accountRules"
                  validate-on-blur
                  v-mask="'####################'"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2" class="pt-0">
                <v-text-field
                  label="Dígito"
                  maxlength="1"
                  v-model="digit"
                  placeholder="0"
                  validate-on-blur
                  v-mask="'#'"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row class="row-buttons-create">
            <v-col md="6" cols="12">
              <v-btn
                @click="goToStartTransfer"
                class="button"
                color="#C4C4C4"
                :x-large="$vuetify.breakpoint.smAndDown"
                block
                rounded
                dark
              >
                Voltar
              </v-btn>
            </v-col>
            <v-col md="6" cols="12">
              <v-btn
                class="button"
                @click="goToInputDataBank()"
                color="#D70472"
                :x-large="$vuetify.breakpoint.smAndDown"
                :disabled="btnContinue"
                block
                rounded
                :dark="!btnContinue"
                :light="btnContinue"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- Dados da conta -->
        <v-col v-if="isInputData" cols="12" md="10" class="mt-0 pt-0">
          <v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="9" class="pl-0 pr-0 pb-0">
              <v-row class="title title-mobile-transfer">
                Saldo total disponível na conta digital:
              </v-row>
              <v-row>
                <v-col cols="8" class="pt-2 pl-0 pb-0">
                  <v-text-field
                    class="value-account-balance"
                    :value="service.formatCurrency(getDigitalBalance)"
                    single-line
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="7" class="pl-0 pr-0 pb-0 pt-0">
              <v-row class="title-transfer title-mobile">
                Dados da transferência
              </v-row>
              <v-row>
                <h4 style="font-weight: normal">
                  para <strong>{{ getAliasInformation.data.aliasAccountHolder.name }}</strong>
                </h4>
              </v-row>
            </v-col>
            <v-form ref="formData" lazy-validation>
              <v-row>
                <v-col cols="12" md="7" class="pl-0 pr-0 ml-0 pb-0">
                  <v-text-field
                    ref="value"
                    class="input-collect"
                    @change="editValue"
                    v-money="money"
                    required
                    maxlength="20"
                    validate-on-blur
                    :rules="valueRules"
                    v-model.lazy="value"
                    :append-icon="showIconEditValue ? 'mdi-pencil' : undefined"
                    @click:append="enableEditValue"
                    :readonly="valueIsReadOnly"
                    color="#D70472"
                    >{{ value }}</v-text-field
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" class="pl-0 pr-0 pt-0">
                Saldo em conta:<strong> {{ service.formatCurrency(getDigitalBalance) }} </strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-0 pr-0 pb-0">
            <h4>Descrição</h4>
          </v-col>
          <v-col cols="12" md="9" class="input-msg pl-0 pt-0 pb-md-0 pb-12 pr-0">
            <v-textarea
              class="box-msg pb-0 pt-0"
              background-color="#F2F2F2"
              placeholder="Escreva uma mensagem..."
              v-model="msg"
              maxlength="140"
              counter="140"
              color="#D70472"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="9"
            class="pl-0 pr-0 pt-md-3 pt-10 pb-0"
            :align="$vuetify.breakpoint.mdAndDown ? 'center' : ''"
          >
            <h4 class="mt-10 mt-md-3">Destinatário</h4>
          </v-col>
          <v-col cols="12" md="9" class="pb-0 pt-0 data-keys">
            <v-row class="pl-0 pr-0 pb-0" justify="space-between">
              <v-col cols="6" class="pl-0 pr-0">
                <h5>Nome</h5>
                <p>{{ getAliasInformation.data.aliasAccountHolder.name }}</p>
                <h5>{{ selectTypeKey }}</h5>
                <p class="alias-data">{{ valueSelectKey }}</p>
              </v-col>
              <v-col cols="6" class="pl-0 pr-0">
                <h5>Banco</h5>
                <p>{{ getAliasInformation.data.psp.name }}</p>
                <h5>Tipo de operação</h5>
                <p>{{ tipoOperacao }}</p>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pr-0 pl-0 pt-0" cols="12" md="9">
            <v-row>
              <v-col
                class="pt-0 btn-cancel-create-qr"
                :class="{ 'col-12': $vuetify.breakpoint.mdAndDown }"
              >
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="button"
                  @click="backToInsertKey"
                  color="#C4C4C4"
                  block
                  rounded
                  dark
                >
                  Voltar
                </v-btn>
                <v-btn
                  v-else
                  class="button"
                  @click="backToInsertKey"
                  color="#8C8C8C"
                  :x-large="$vuetify.breakpoint.smAndDown"
                  block
                  rounded
                  outlined
                >
                  Voltar
                </v-btn>
              </v-col>

              <v-col
                class="pb-md- pb-5 pt-0 btn-create-qr"
                :class="{ 'col-12': $vuetify.breakpoint.mdAndDown }"
              >
                <v-btn
                  class="button"
                  @click="openModalTransfer()"
                  color="#D70472"
                  :disabled="btnContinue"
                  block
                  rounded
                  :x-large="$vuetify.breakpoint.smAndDown"
                  :dark="!btnContinue"
                  :light="btnContinue"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-col>

        <!-- Dados da conta Pix -->
        <v-col v-if="isInputDataPix" cols="12" md="10" class="mt-0 pt-0">
          <v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="9" class="pl-0 pr-0 pb-0">
              <v-row class="title title-mobile-transfer">
                Saldo total disponível na conta digital:
              </v-row>
              <v-row>
                <v-col cols="8" class="pt-2 pl-0 pb-0">
                  <v-text-field
                    class="value-account-balance"
                    :value="service.formatCurrency(getDigitalBalance)"
                    single-line
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="7" class="pl-0 pr-0 pb-0 pt-0">
              <v-row class="title-transfer title-mobile">
                Dados da transferência
              </v-row>
              <v-row>
                <h4 style="font-weight: normal">
                  para
                  <strong>{{ receipt.source.name }}</strong>
                </h4>
              </v-row>
            </v-col>
            <v-form ref="formDataPix" lazy-validation>
              <v-row>
                <v-col cols="12" md="7" class="pl-0 pr-0 ml-0 pb-0">
                  <v-text-field
                    class="input-collect"
                    v-money="money"
                    required
                    maxlength="20"
                    :value="service.formatCurrency(receipt.value)"
                    readonly
                    color="#D70472"
                    >{{ valuePix }}</v-text-field
                  >
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" class="pl-0 pr-0 pt-0">
                Saldo em conta:<strong>
                  {{ service.formatCurrency(getDigitalBalance) }}
                </strong>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pl-0 pr-0 pb-0">
            <h4>Descrição</h4>
          </v-col>
          <v-col cols="12" md="9" class="input-msg pl-0 pt-0 pb-md-0 pb-12 pr-0">
            <v-textarea
              class="box-msg pb-0 pt-0"
              background-color="#F2F2F2"
              placeholder="Escreva uma mensagem..."
              v-model="msg"
              maxlength="140"
              counter="140"
              color="#D70472"
            ></v-textarea>
          </v-col>
          <v-col
            cols="12"
            md="9"
            class="pl-0 pr-0 pt-md-3 pt-10 pb-0"
            :align="$vuetify.breakpoint.mdAndDown ? 'center' : ''"
          >
            <h4 class="mt-10 mt-md-3">Destinatário</h4>
          </v-col>
          <v-col cols="12" md="9" class="pb-0 pt-0 data-keys">
            <v-row class="pl-0 pr-0 pb-0" justify="space-between">
              <v-col cols="6" class="pl-0 pr-0">
                <h5>Nome</h5>
                <p>{{ receipt.source.name }}</p>
                <h5>CNPJ/CPF</h5>
                <p class="alias-data">
                  {{ this.service.maskToDocument(receipt.source.documentMasked) }}
                </p>
              </v-col>
              <v-col cols="6" class="pl-0 pr-0">
                <h5>Tipo de operação</h5>
                <p>{{ tipoOperacao }}</p>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="pr-0 pl-0 pt-0" cols="12" md="9">
            <v-row>
              <v-col
                class="pt-0 btn-cancel-create-qr"
                :class="{ 'col-12': $vuetify.breakpoint.mdAndDown }"
              >
                <v-btn
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="button"
                  @click="backToInsertKey"
                  color="#C4C4C4"
                  block
                  rounded
                  dark
                >
                  Voltar
                </v-btn>
                <v-btn
                  v-else
                  class="button"
                  @click="backToInsertKey"
                  color="#8C8C8C"
                  :x-large="$vuetify.breakpoint.smAndDown"
                  block
                  rounded
                  outlined
                >
                  Voltar
                </v-btn>
              </v-col>

              <v-col
                class="pb-md- pb-5 pt-0 btn-create-qr"
                :class="{ 'col-12': $vuetify.breakpoint.mdAndDown }"
              >
                <v-btn
                  class="button"
                  @click="transferDevolution()"
                  color="#D70472"
                  :disabled="btnContinue"
                  block
                  rounded
                  :x-large="$vuetify.breakpoint.smAndDown"
                  :dark="!btnContinue"
                  :light="btnContinue"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <ModalTransferReturn
            :dialog="dialogTransferReturn"
            @closeModal="closeModalReturn"
            @openDialogTransferReturn="openDialogTransferReturn"
            :totalAmount="`${service.formatCurrency(receipt.value)}`"
            :transactionId="`${receipt.transactionId}`"
            :name="`${receipt.source.name} `"
            :message="msg"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VMoney } from "v-money";
import { mask } from "vue-the-mask";
import service from "../../../../service/index";
import ModalTransfer from "./ModalTransfer";
import ModalReceipt from "./ModalReceipt";
import ModalTransferReturn from "./ModalTransferReturn.vue";

export default {
  directives: {
    money: VMoney,
    mask
  },
  components: {
    ModalTransfer,
    ModalReceipt,
    ModalTransferReturn
  },
  data() {
    return {
      service,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: true
      },
      isStartTransfer: true,
      isSelectKey: false,
      isInsertDataAccount: false,
      isInputData: false,
      isInputDataPix: false,
      isKeyWithMask: false,
      selectChangeKey: true,
      selectTypeKey: "",
      valueSelectKey: "",
      selectBank: "",
      selectTypeAccount: "",
      optionsKeys: [
        { name: "E-mail" },
        { name: "CNPJ" },
        { name: "CPF" },
        { name: "Celular" },
        { name: "Aleatória" }
      ],
      value: 0.0,
      valuePix: this.valueAmount,
      tipoOperacao: "Pix",
      msg: "",
      btnContinue: false,
      optionsTypeAccount: [{ type: "Corrente" }, { type: "Poupança" }],
      showIconEditValue: false,
      valueIsReadOnly: false,
      msgEmailRequerid: false,
      dialogTransfer: false,
      dialogReceipt: false,
      dialogTransferReturn: false,
      document: "",
      branch: "",
      account: "",
      digit: "",
      bankId: "",
      emailRules: [
        valueInput => !!valueInput || "E-mail obrigatório",
        valueInput => /.+@.+\..+/.test(valueInput) || "E-mail inválido"
      ],
      documentRules: [valueInput => (!!valueInput && valueInput.length >= 18) || "CNPJ inválido"],
      personalDocumentRules: [
        valueInput => (!!valueInput && valueInput.length >= 14) || "CPF inválido"
      ],
      aleatoryRules: [valueInput => (!!valueInput && valueInput.length >= 36) || "Chave inválida"],
      mobileNumberRules: [
        valueInput => !!valueInput || "Número de celular obrigatório",
        valueInput => (valueInput && valueInput.length >= 14) || "Deve conter 11 números"
      ],
      selectKeyRules: [valueInput => !!valueInput || "Campo obrigatório"],
      valueRules: [
        valueInput =>
          (!!valueInput && this.service.cleanCurrencyAll(valueInput) >= 0.01) ||
          "Digite um valor válido",
        valueInput =>
          (!!valueInput && this.service.cleanCurrencyAll(valueInput) < this.getDigitalBalance) ||
          "Saldo não disponível"
      ],
      bankRules: [valueInput => !!valueInput || "Banco obrigatório"],
      accountTypeRules: [valueInput => !!valueInput || "Tipo de conta obrigatório"],
      branchRules: [
        valueInput => !!valueInput || "Agência obrigatória",
        valueInput => valueInput.length <= 4 || "Agência inválida"
      ],
      accountRules: [valueInput => !!valueInput || "Conta obrigatória"]
    };
  },
  watch: {
    selectBank(value) {
      const array = this.getBanks.data.psps;
      for (let i = 0; i <= array.length; i++) {
        if (array[i].name === this.selectBank) {
          this.bankId = array[i].id;
        }
      }
    },
    selectTypeKey() {
      this.checkIsKeyWithMask();
      this.valueSelectKey = "";
    }
  },
  computed: {
    ...mapGetters({
      erro: "Pix/getError",
      getDigitalBalance: "DigitalAccount/getBalance",
      getBanks: "Pix/getBanks",
      getAliasInformation: "Pix/getAliasInformation",
      receipt: "DigitalAccount/getReceiptTransfer"
    })
  },
  methods: {
    ...mapActions({
      setBalance: "DigitalAccount/setBalance",
      getPixBanks: "Pix/getPixBanks",
      getAliasInfo: "Pix/getAliasInfo",
      validateTransfer: "Pix/validateTransfer",
      receiptTransfer: "DigitalAccount/receiptTransfer",
      instantPaymentTransferValidate: "Pix/instantPaymentTransferValidate"
    }),
    changeKey() {
      this.valueSelectKey = "";
      this.selectChangeKey = true;
    },
    editValue() {
      if (this.value != "R$ 0,00") {
        this.showIconEditValue = true;
        this.valueIsReadOnly = true;
      } else {
        this.showIconEditValue = false;
        this.valueIsReadOnly = false;
      }
    },
    enableEditValue() {
      this.valueIsReadOnly = false;
      this.$refs.value.focus();
      if (this.value == "R$ 0,00") {
        this.showIconEditValue = false;
      }
    },
    async openModalTransfer() {
      if (this.$refs.formData.validate()) {
        this.btnContinue = true;
        await this.validateTransfer({
          name: this.getAliasInformation.data.aliasAccountHolder.name,
          document: this.getAliasInformation.data.aliasAccountHolder.taxIdentifier.taxId,
          totalAmount: this.service.cleanCurrency(this.value),
          description: this.msg,
          transactionType: "PIX",
          aliasType: {
            alias: this.getAliasInformation.data.alias,
            endToEndIdQuery: this.getAliasInformation.data.endToEndId
          },
          bankDetail: {
            branch: this.getAliasInformation.data.accountDestination.branch,
            account: this.getAliasInformation.data.accountDestination.account,
            accountType: this.getAliasInformation.data.accountDestination.accountType,
            paymentServiceProvider: {
              id: this.getAliasInformation.data.psp.id,
              name: this.getAliasInformation.data.psp.name
            }
          }
        });
        if (!this.erro) {
          this.dialogTransfer = true;
        } else {
          this.btnContinue = false;
        }
      }
    },
    closeModalTransfer() {
      this.dialogTransfer = false;
      this.btnContinue = false;
    },
    getRuleSelectTypeKey() {
      switch (this.selectTypeKey) {
        case "E-mail":
          return this.emailRules;
        case "CNPJ":
          return this.documentRules;
        case "CPF":
          return this.personalDocumentRules;
        case "Celular":
          return this.mobileNumberRules;
        case "Aleatória":
          return this.aleatoryRules;
        default:
          return undefined;
      }
    },
    checkIsKeyWithMask() {
      this.selectChangeKey = false;
      if (
        this.selectTypeKey === "CNPJ" ||
        this.selectTypeKey === "CPF" ||
        this.selectTypeKey === "Celular" ||
        this.selectTypeKey === "Aleatória"
      ) {
        this.isKeyWithMask = true;
      } else {
        this.isKeyWithMask = false;
      }
    },
    getFormatMask() {
      switch (this.selectTypeKey) {
        case "CNPJ":
          return "##.###.###/####-##";
        case "CPF":
          return "###.###.###-##";
        case "Celular":
          return "+55(##)#####-####";
        case "Aleatória":
          return "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX";
        default:
          return undefined;
      }
    },
    goToInsertDataAccount() {
      this.isStartTransfer = false;
      this.isInsertDataAccount = true;
    },
    goToReview() {
      this.isStartTransfer = false;
      this.isSelectKey = true;
    },
    validateKey() {
      if (!this.erro) {
        this.isInputData = true;
        this.isSelectKey = false;
      }
      this.btnContinue = false;
    },
    async goToInputData() {
      if (this.$refs.formKey.validate()) {
        this.btnContinue = true;
        if (this.selectTypeKey === "CNPJ" || this.selectTypeKey === "CPF") {
          await this.getAliasInfo(this.service.cleanDocument(this.valueSelectKey));
          this.validateKey();
        } else if (this.selectTypeKey === "Celular") {
          await this.getAliasInfo(this.service.cleanMobilePhone(this.valueSelectKey));
          this.validateKey();
        } else if (this.selectTypeKey === "E-mail" || this.selectTypeKey === "Aleatória") {
          await this.getAliasInfo(this.valueSelectKey);
          this.validateKey();
        }
      }
    },
    goToStartTransfer() {
      this.selectTypeKey = "";
      this.value = "";
      this.msg = "";
      this.document = "";
      this.selectBank = "";
      this.selectTypeAccount = "";
      this.branch = "";
      this.account = "";
      this.digit = "";
      this.valueSelectKey = "";
      this.selectChangeKey = true;
      this.isSelectKey = false;
      this.isInsertDataAccount = false;
      this.isStartTransfer = true;
      this.isInputData = false;
    },
    async goToInputDataBank() {
      if (this.$refs.formDataBank.validate() && this.$refs.formDataValue.validate()) {
        await this.validateTransfer({
          document: this.service.cleanDocument(this.document),
          totalAmount: this.service.cleanCurrency(this.value),
          description: this.msg,
          bankDetail: {
            branch: this.branch,
            account: this.account,
            accountType: this.selectTypeAccount === "Corrente" ? "CC" : "CP",
            digit: this.digit,
            paymentServiceProvider: {
              id: this.bankId
            }
          }
        });
        if (!this.erro) {
          this.dialogTransfer = true;
        } else {
          this.btnContinue = false;
        }
      } else {
        window.scrollTo(0, 0);
      }
    },
    backToInsertKey() {
      this.value = "";
      this.msg = "";
      this.isInputData = false;
      this.isInputDataPix = false;
      this.isSelectKey = true;
    },
    openDialogReceipt() {
      this.dialogReceipt = true;
    },
    closeModalReceipt() {
      this.dialogReceipt = false;
      this.goToStartTransfer();
    },
    openDialogTransferReturn() {
      this.closeModalReturn();
    },
    closeModalReturn() {
      this.dialogTransferReturn = false;
      this.btnContinue = false;
    },
    async PixTransferReturn() {
      if (this.$route.query.hasDevolution) {
        this.isStartTransfer = false;
        this.isInputDataPix = true;
        await this.receiptTransfer(this.receipt.transactionId);
      }
    },
    async transferDevolution() {
      if (this.$refs.formDataPix.validate()) {
        this.btnContinue = true;
        await this.instantPaymentTransferValidate({
          totalAmount: this.receipt.value.toString(),
          description: this.msg,
          transactionId: this.receipt.transactionId
        });
        if (!this.erro) {
          this.dialogTransferReturn = true;
        } else {
          this.btnContinue = false;
        }
      }
    }
  },

  async created() {
    await this.setBalance();
    await this.getPixBanks();
  },
  mounted() {
    this.PixTransferReturn();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/pix.scss";
</style>
